import React, { useEffect, useState } from 'react'
import Router from 'next/router'

// 404を適用するタイミングで要確認
const redirectPath = /^\/(interview|patients|treatments)\/.+/g

const Custom404: React.FC = () => {
  const [isNotFound, setIsNotFound] = useState(false)

  useEffect(() => {
    // Your condition that can validate the URL
    const pathName = window.location.pathname

    if (pathName.match(redirectPath)) {
      Router.replace(pathName) // Redirect to the right page...
    } else {
      setIsNotFound(true)
    }
  }, [])

  if (isNotFound) return <h1>404 - Page Not Found</h1>

  return null
}

export default Custom404
